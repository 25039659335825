<template>
  <div>
    <div class="header-top">
      <div class="left-wrap">
        <div class="header-title">当前学员</div>
        <div class="student-info">
          <div>
            <img :src="studentProfile.headImageUrl ? studentProfile.headImageUrl : defaultUrl" class="student-avtor" />
          </div>
          <div>
            <div>
              <span style="margin-left: 10px">{{ studentProfile.fullName }}</span>
              <span style="color: rgb(102, 102, 102)">（{{ studentProfile.code }}）</span>
              <!-- <span class="promotionAmbassador" v-if="studentDetail.reseller!==null"
                  >
                  <img src="@/assets/icon-ambassador.png" class="ambassador" />
                  推广大使
                </span> -->
            </div>
            <!-- <div class="bot-infowrap">
              <img src="@/assets/timeicon.png" style="width: 12px; height: 12px" />
              <span class="remaining-title">剩余课时:</span>
              <span class="remaining-text"
                >中文
                <span
                  v-if="studentDetail.chineseRemaining"
                  :class="[
                    'remaining-num',
                    studentDetail.chineseRemaining <= 8 && studentDetail.chineseRemaining >= 1 ? 'warning' : '',
                  ]"
                  >{{ studentDetail.chineseRemaining }}</span
                >
                <span v-else style="color: rgba(0, 0, 0, 0.85)">无</span>
               
              </span>

              <span class="line"></span>
              <span class="remaining-text"
                >数学
                <span
                  v-if="studentDetail.mathRemaining"
                  :class="[
                    'remaining-num',
                    studentDetail.mathRemaining <= 8 && studentDetail.mathRemaining >= 1 ? 'warning' : '',
                  ]"
                  >{{ studentDetail.mathRemaining }}</span
                >
                 <span v-else style="color: rgba(0, 0, 0, 0.85)">无</span>
              </span>
         
           
             
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="schedule-wrap">
      <div class="schedule-title">去排课</div>
      <div class="schedule-form">
        <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
          <a-form-item label="上课平台">
            <a-select
              allow-clear
              v-decorator="['platform', { rules: [{ required: true, message: '请选择上课平台!' }] }]"
              placeholder="请选择"
              @change="changePlatForm"
            >
              <a-select-option :value="i.value" v-for="i of scheduleOptions.platforms" :key="i.value">
                {{ i.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="班级容量" v-if="!!form.getFieldValue('platform')">
            <a-input-number
              ref="capacity"
              allow-clear
              v-decorator="[
                'capacity',
                {
                  rules: [
                    {
                      required: true,
                      message:
                        form.getFieldValue('platform') !== 'TALK_CLOUD'
                          ? '请输入班级容量(1-1000数字)'
                          : '请输入班级容量(1-20000数字)',
                      trigger: 'change',
                      type: 'integer',
                      min: 1,
                      max: form.getFieldValue('platform') !== 'TALK_CLOUD' ? 1000 : 20000,
                    },
                  ],
                },
              ]"
              style="width: 100%"
              :placeholder="
                form.getFieldValue('platform') !== 'TALK_CLOUD'
                  ? '请输入班级容量(1-1000数字)'
                  : '请输入班级容量(1-20000数字)'
              "
            />
          </a-form-item>
          <a-form-item label="上课类型">
            <a-select
              v-decorator="['classType', { rules: [{ required: true, message: '请选择上课类型!' }] }]"
              placeholder="请选择"
            >
              <a-select-option value="PRIVATE"> 私教课 </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="上台人数" v-if="platform !== 'TALK_CLOUD'">
            <a-input v-decorator="['seatNum', { rules: [{ required: true, message: '请填写上台人数!' }] }]" />
          </a-form-item>
          <a-form-item label="科目">
            <a-select
              @change="handleSelectChange"
              v-decorator="['subject', { rules: [{ required: true, message: '请选择科目!' }] }]"
              placeholder="请选择"
            >
              <a-select-option value="CHINESE"> 中文 </a-select-option>
              <!-- <a-select-option value="MATH">
                    数学
                </a-select-option> -->
            </a-select>
          </a-form-item>
          <a-form-item label="课程">
            <a-select
              labelInValue
              allow-clear
              show-search
              @change="selectCourse"
              :filter-option="filterOption"
              option-filter-prop="children"
              v-decorator="['courseId', { rules: [{ required: true, message: '请选择课程!' }] }]"
              placeholder="请选择"
            >
              <a-select-option :value="i.uuid" v-for="i of courseArr" :key="i.label">
                {{ i.courseName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="授课语言">
            <a-select
              option-filter-prop="children"
              :filter-option="filterOption"
              show-search
              v-decorator="['teachingLanguage']"
              @change="handleSelectLanguage"
              allowClear
            >
              <a-select-option v-for="item in languageOptions" :key="item.value" :value="item.value">
                {{ item.label }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
            <a-popconfirm placement="top" @confirm="handleSubmit" v-if="languageConfirm && !this.selectedLanguage">
              <template slot="title">
                <p>请确认不用设置授课语言吗</p>
              </template>
              <a-button type="primary"> 立即排课 </a-button>
            </a-popconfirm>
            <a-button type="primary" html-type="submit" :loading="iconLoading" v-else> 立即排课 </a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <a-modal
      title="操作提示"
      :visible="visible"
      @ok="saveClass"
      @cancel="cancelClass"
      okText="确定"
      cancelText="上一步"
    >
      <div style="padding: 0 0 10px 20px">系统自动给该学生私教课生成班级：</div>
      <a-form :form="form1" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
        <a-form-item label="班级名称">
          <a-input
            v-decorator="[
              'className',
              {
                rules: [{ required: true, message: '请输入班级名称!' }],
              },
            ]"
            style="width: 100%"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import storage from 'store';
import sessionStorage from 'store/storages/sessionStorage';
import { getSubjectCourse, querySpecifiedClass, createdSpecifiedClass } from '@/api/headTeacher';
import { languageOptions } from '@/utils/const';
import { loadScheduleOptions, getDefaultPlatform, getCourse } from '@/api/schedule';

const session = storage.createStore(sessionStorage);

export default {
  name: 'toScheduleClasses',
  data() {
    return {
      languageOptions,
      platform: '',
      scheduleOptions: {},
      iconLoading: false,
      defaultUrl: 'https://cdnwukong.com/public/im/user.png',
      selfClassName: '',
      userInfo: {},
      formObj: {},
      visible: false,
      courseArr: [],
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      studentDetail: {},
      studentProfile: session.get('studentProfile'),
      form1: this.$form.createForm(this),
      languageConfirm: false,
      selectedLanguage: undefined,
    };
  },
  methods: {
    changePlatForm(val) {
      this.platform = val;
    },
    selectCourse(val) {
      if (!val) {
        this.selfClassName = undefined;
        this.languageConfirm = false;

        return;
      }

      this.selfClassName = val.label.replace(/\ /g, '').replace(/\s/g, '') + this.studentProfile.fullName;

      const data = this.courseArr.find((item) => item.uuid === val.key);
      this.languageConfirm = data?.subjectType?.nodeContent?.subject === 'CHINESE';
    },
    handleSelectLanguage(e) {
      console.log(123);

      this.selectedLanguage = e;
    },
    saveClass() {
      this.form1.validateFields((err, values) => {
        if (!err) {
          // 创建班级名称
          this.createdSpecifiedClass(values);
        }
      });
    },
    cancelClass() {
      this.visible = false;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.formObj = values;
          console.log('Received values of form: ', values);
          this.querySpecifiedClass(values);
        }
      });
    },
    handleSelectChange(value) {
      console.log(value);
      this.courseArr = [];
      this.getSubjectCourse(value);
    },
    getSubjectCourse(val) {
      const params = {
        subject: val, // 科目
        shelfStatus: ['ON'],
        frozen: false,
      };
      getCourse(params).then((courseArr) => {
        this.courseArr = courseArr;
      });
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0;
    },
    querySpecifiedClass(obj) {
      this.iconLoading = true;
      const params = {
        studentId: this.studentProfile.uuid,
        courseId: obj.courseId.key,
        classType: obj.classType,
        platform: obj.platform,
        classStatus: 'NORMAL',
        teachingLanguage: obj.teachingLanguage,
      };
      querySpecifiedClass(params).then((res) => {
        this.iconLoading = false;
        // 判断有无指定班级,有跳转
        const data = res.data.content;
        if (data == null) {
          this.visible = true;
          // 生成一个班级名称
          this.$nextTick(() => {
            let label = '';
            if (obj.platform === 'CLASS_IN') {
              label = obj.seatNum;
            } else if (obj.platform === 'TALK_CLOUD') {
              label = obj.capacity;
            }
            this.form1.setFieldsValue({
              className: `1v${label}${this.selfClassName}`,
            });
          });
        } else {
          // 跳到classDetail页面，传一个classId过去
          this.$router.push({ path: '/workoutSchedule/classDetail', query: { classId: data.uuid } });
        }
      });
    },
    createdSpecifiedClass(obj) {
      // 创建班级
      const params = {
        className: obj.className, // 班级名
        classType: this.formObj.classType, // 班级类型
        subject: this.formObj.subject, // 科目
        courseId: this.formObj.courseId.key, // 课程ID
        studentId: this.studentProfile.uuid, // 学生Id
        ownerId: this.userInfo.userID, // 科目对应的班主任Id
        capacity: this.formObj.capacity,
        platform: this.formObj.platform,
        teachingLanguage: this.formObj.teachingLanguage,
        data: {
          seatNum: this.formObj.seatNum, // 上台人数
        },
      };
      createdSpecifiedClass(params).then((res) => {
        // 跳到classDetail页面，传一个classId过去
        this.$router.push({ path: '/workoutSchedule/classDetail', query: { classId: res.data.content.uuid } });
      });
    },
  },
  created() {},
  mounted() {
    loadScheduleOptions((res) => {
      this.scheduleOptions = res;
    });

    const obj = storage.get('userInfo');
    this.userInfo = obj;
    this.studentDetail = this.$route.params.studentDetail;
    const params = {
      uuid: this.studentProfile.uuid,
    };
    getDefaultPlatform(params).then((res) => {
      this.platform = res.data.content;
      this.form.setFieldsValue({
        classType: 'PRIVATE',
        seatNum: 1,
        subject: 'CHINESE',
        platform: res.data.content,
      });
    });

    this.getSubjectCourse('CHINESE');
  },
};
</script>
<style lang="less" scoped>
/deep/ .ant-descriptions-item-label {
  color: rgba(31, 45, 61, 0.4);
}
/deep/ .ant-descriptions-item-content {
  color: #1f2d3d;
}
/deep/ .ant-descriptions-title {
  &::before {
    content: '';
    display: block;
    float: left;
    margin: 4px 6px 0 0;
    width: 3px;
    height: 16px;
    background-color: #41cc83;
    border-radius: 0px 2px 2px 0px;
  }
}
.header-top {
  padding: 18px 20px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.header-title {
  font-size: 16px;
  font-weight: 600;
}
.student-avtor {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}
.student-info {
  font-size: 16px;
  color: #333;
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.operator-btn {
  color: #1f2d3d;
  font-size: 13px;
  padding: 5px 8px;
  margin-left: 10px;
  border: 1px solid rgba(31, 45, 61, 0.18);
  cursor: pointer;
}
.promotionAmbassador {
  background: #ffbf00;
  color: #fff;
  font-size: 13px;
  padding: 4px 6px;
  margin-left: 10px;
  border-radius: 3px;
}
.ambassador {
  width: 9px;
  height: 9px;
}
.schedule-wrap {
  background-color: #fff;
  padding: 18px 20px 0;
}
.remaining-title {
  color: rgba(0, 0, 0, 0.65);
  margin: 0 8px 0 7px;
}
.bot-infowrap {
  margin-top: 12px;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.remaining-text {
  color: rgba(0, 0, 0, 0.4);
  margin-right: 12px;
}
.warning {
  color: #ff4d4f;
}
.remaining-num {
  margin: 0 5px;
  color: rgba(0, 0, 0, 0.85);
}
.line {
  width: 1px;
  height: 14px;
  margin: 0 12px 0 0;
  background-color: rgba(0, 0, 0, 0.15);
  border-radius: 0px 2px 2px 0px;
}
.schedule-title {
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5;
  &::before {
    content: '';
    display: block;
    float: left;
    margin: 4px 6px 0 0;
    width: 3px;
    height: 16px;
    background-color: #41cc83;
    border-radius: 0px 2px 2px 0px;
  }
}
.schedule-form {
  width: 800px;
  margin: 0 auto;
  padding-bottom: 50px;
}
</style>
